<template>
  <t-modal name="complete" class="z-45">
    <form @submit.prevent class="p-2" autocomplete="off">
      <p class="mb-2 font-medium text-gray-700">Complete seu perfil</p>
      <p class="mb-4 text-sm text-gray-500">Só faltam algumas informações...</p>
      <label for="document" class="block text-sm font-medium text-gray-700">CPF/CNPJ</label>
      <input @change="getDocument()" v-model="document" v-mask="['###.###.###-##', '##.###.###/####-##']" type="text" autocomplete="off" name="document" id="document" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
      <label for="name" class="mt-2 block text-sm font-medium text-gray-700">{{ document.length > 14 ? 'Razão Social' : 'Nome Completo' }}</label>
      <input v-model="name" type="text" name="name" id="name" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
      <div v-if="document.length > 14">
        <div class="mt-2 grid grid-cols-2 space-x-3">
          <div>
            <label for="nameFantasia" class="block text-sm font-medium text-gray-700">Nome Social</label>
            <input v-model="nameFantasia" type="text" name="nameFantasia" id="nameFantasia" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div>
            <label for="ie" class="block text-sm font-medium text-gray-700">Inscrição Estadual</label>
            <input v-model="ie" type="text" name="ie" id="ie" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
        <label class="mt-4 mb-3 block text-sm font-medium text-gray-700">Endereço Comercial</label>
        <div class="grid grid-cols-6 gap-3">
          <div class="col-span-3">
            <label for="cep" class="block text-sm font-medium text-gray-700">Cep</label>
            <input @change="getAddress()" v-model="address.postcode" v-mask="'#####-###'" type="text" name="cep" id="cep" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-3">
            <label for="address" class="block text-sm font-medium text-gray-700">Endereço</label>
            <input v-model="address.street" type="text" name="address" id="address" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="number" class="block text-sm font-medium text-gray-700">Número</label>
            <input v-model="address.number" type="text" name="number" id="number" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-2">
            <label for="complement" class="block text-sm font-medium text-gray-700">Complemento</label>
            <input v-model="address.complement" type="text" name="complement" autocomplete="off" id="complement" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-3">
            <label for="neighborhood" class="block text-sm font-medium text-gray-700">Bairro</label>
            <input v-model="address.neighborhood" type="text" name="neighborhood" autocomplete="off" id="neighborhood" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-3">
            <label for="city" class="block text-sm font-medium text-gray-700">Cidade</label>
            <input v-model=address.city type="text" name="city" id="city" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-3">
            <label for="state" class="block text-sm font-medium text-gray-700">Estado</label>
            <input v-model="address.state" type="text" name="state" id="state" autocomplete="off" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
      </div>
      <div v-else>
        <label for="nasci" class="mt-2 block text-sm font-medium text-gray-700">Data de Nascimento</label>
        <input v-model="nasci" v-mask="'##/##/####'" autocomplete="off" type="text" name="nasci" id="nasci" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
      </div>
      <label for="phone" class="mt-2 block text-sm font-medium text-gray-700">Telefone</label>
      <input v-model="phone" v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off" type="text" name="phone" id="phone" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
      <button @click="save()" type="submit" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
        Salvar
      </button>
    </form>
  </t-modal>
</template>

<script>
import { mask } from 'vue-the-mask'
import { auth, getAddress, getPeople, usersColl, validateDocument } from '@/firebase'

export default {
  data() {
    return {
      ie: '',
      name: '',
      phone: '',
      nasci: '',
      document: '',
      nameFantasia: '',
      address: {
        city: '',
        state: '',
        street: '',
        number: '',
        postcode: '',
        complement: '',
        neighborhood: '',
      }
    }
  },

  methods: {
    async getDocument() {
      if (this.document.length === 18 && !this.nameFantasia.length) {
        if (!this.$store.state.loading) this.$store.commit('setLoading', true)
        await getPeople(this.document).then(result => { 
          this.name = result.name
          this.nameFantasia = result.nameFantasia 
          this.address.city = result.address?.city 
          this.address.complement = result.address?.complement
          this.address.neighborhood = result.address?.neighborhood
          this.address.number = result.address?.number
          this.address.postcode = result.address?.postcode
          this.address.state = result.address?.state
          this.address.street = result.address?.street
        })
        if (this.$store.state.loading) this.$store.commit('setLoading', false)
      }
    },

    async getAddress() {
      if (this.address.postcode.length === 9) {
        if (!this.$store.state.loading) this.$store.commit('setLoading', true)
        await getAddress(this.address.postcode).then(result => {
          this.address.city = result.city 
          this.address.neighborhood = result.neighborhood
          this.address.state = result.state
          this.address.street = result.street
        })
        if (this.$store.state.loading) this.$store.commit('setLoading', false)
      }
    },

    async save() {
      if (!validateDocument(this.document)) { this.$toast.error('Documento inválido'); return }
      if (this.document.length < 18 && (!this.name.length || !this.phone.length || !this.nasci.length)) { this.$toast.error('Preencha todos os campos'); return }
      if (this.document.length < 18 && this.nasci.length !== 10) { this.$toast.error('Data de Nascimento inválida.'); return }
      if (this.document.length === 18 && (!this.name || !this.phone || !this.ie || !this.nameFantasia || !this.address.city || !this.address.street || !this.address.state || !this.address.number || !this.address.postcode || !this.address.neighborhood)) { this.$toast.error('Preencha todos os campos'); return }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.doc(auth.currentUser.uid).update({
        Name: this.name,
        Document: this.document,
        Phone: this.phone,
        ...(!this.nameFantasia && { Nascimento: this.nasci }),
        ...(this.nameFantasia && { Juridico: {
          Ie: this.ie,
          NameFantasia: this.nameFantasia,
          Address: {
            City: this.address.city,
            State: this.address.state,
            Street: this.address.street,
            Number: this.address.number,
            Postcode: this.address.postcode,
            Complement: this.address.complement,
            Neighborhood: this.address.neighborhood
          }
        } })
      }).then(() => this.$toast.success('Cadastro finalizado com sucesso')).catch(() => this.$toast.error('Falha ao salvar, tente novamente'))
      this.$modal.hide('complete')
      this.$store.dispatch('fetchUserProfile', auth.currentUser)
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  },

  directives: { mask }
}
</script>